<template>
  <div>
    <!-- picDE0C2B3F4A4EFB6407802F1A44BBC34A.jp -->
    <img class="img-large rounded" src="@/assets/news-network/build-your-lu-dreams.jpg">

    <p>
      The LEGO Universe News Network introduces new “news-themes”! For each terrific topic, we’ll feature content that opens the door on new game details, beginning with “building”!
    </p>
    <p>
      This series shines a light on the many ways you can make models in LEGO Universe!
    </p>
    <p>
      For starters, players will easily toss together LEGO Universe creations with “quick building.” Snappy and fast, this is the primary type of building found in popular LEGO video games like Star Wars™, Batman™ and Indiana Jones™.
    </p>
    <p>
      To quick build in LEGO Universe, you’ll simply push a button that starts your custom LEGO minifigure swiftly snapping together a predetermined model. Quick building is great for keeping the gameplay going at turbo-speed, even in the middle of intense action!
    </p>
    <p>
      Check out this concept art sketch of a quick build where a dragon's hotdog cart needs to be swiftly reassembled:
    </p>
    <!-- picF0D8B7DA8BD5124D9F86C8759E4B095F.jpg -->
    <img class="rounded" style="height: 680px;" src="@/assets/news-network/build-your-lu-dreams-2.jpg">
    <p><font size="1"><i><b>Concept art sketch of a "quick build"</b></i></font></p>

    <p>
      You’ll also discover other <b>brand new ways to build</b> in LEGO Universe, the most gigantic virtual playground LEGO has ever created! “Choice building,” for example, will let you decide what a quick build will become by choosing from a range of predetermined models.
    </p>
    <p>
      Even beginning builders can create amazing models with quick builds and choice building, but this Build your LEGO Universe dreams series is just getting started! Stay tuned for part 2, where we’ll introduce more fantastic new ways to build that you’re only going to find in the first massively multiplayer online game for LEGO fans!
    </p>
    <p>
      <br><br><font size="1"><b>Please note:</b> Concept art creations are part of the creative process. There is no guarantee that they will be in the game.</font>
    </p>
  </div>
</template>
